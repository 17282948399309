<script lang="ts" setup>
	useHead({
		title: "Aston Martin",
		meta: [{ name: "description", content: "" }],
	});

	useHead({
		titleTemplate: (string) => {
			return string ? `${string} - Aston Martin` : "Aston Martin";
		},
	});
</script>

<template>
	<slot />
</template>

<style scoped lang="scss"></style>
